<template lang="pug">
v-scrollable.scroll-wrapper-feed-page(@scroll-complete="handleScrollComplete")
      .kt-permissions.row.justify-content-center.pt-5.kt-padding-b-100
        .st-container
          .nav-wrapper
            ui-nav-tabs.desktop-nav(:options="tabs")
            dropdown-nav.mobile-nav(:title="'ui.labels.section' | translate "  :options="tabs")
          router-view
</template>

<script>
import UiNavTabs from '@/components/ui/NavTabs'
import DropdownNav from '@/components/ui/DropdownNav'
import { mapActions } from 'vuex'
export default {
  name: 'Feed',

  components: {
    UiNavTabs,
    DropdownNav
  },

  data: () => ({
    tabs: [
      { id: 0, title: 'pages.feed.all', path: '/feed/all' },
      { id: 1, title: 'pages.feed.unseen', path: '/feed/unseen' },
      { id: 2, title: 'pages.feed.open_confirmations', path: '/feed/open-confirmations' }
    ],
    page: 1,
    pageUnseen: 1,
    blockScroll: false,
    blockScrollUnseen: false
  }),
  methods: {
    ...mapActions('notifications', ['onNotificationsSubscribe', 'getUnseenNotifications']),

    async handleScrollComplete () {
      if ((this.$route.path === '/feed/all' || this.$route.path === '/feed/open-confirmations') && !this.blockScroll) {
        this.page += 1
         const { headers } = await this.onNotificationsSubscribe(this.page)
         if (+headers['x-pagination-page-count'] > this.page) {
            this.blockScroll = false
          } else {
            this.blockScroll = true
          }
      } else if (this.$route.path === '/feed/unseen' && !this.blockScrollUnseen) {
        this.pageUnseen += 1
        const { headers } = await this.getUnseenNotifications(this.pageUnseen)
        if (+headers['x-pagination-page-count'] > this.pageUnseen) {
            this.blockScrollUnseen = false
          } else {
            this.blockScrollUnseen = true
          }
      }
    }
  }
}
</script>

<style lang="scss">
  .kt-permissions {
    .nav-wrapper {
      margin-bottom: 2rem;
    }
  }
  .scroll-wrapper-feed-page{
    height: calc(100vh - 80px) !important;
  }

  .all-notifications-container{
    width:70%;
  }

  .all-notifications-list-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:50px;
    flex-direction: column
  }
</style>
